import styled from "styled-components";
import { BsPhone } from "react-icons/bs";
import LanguageSwitch from "../Language/LanguageSwitch";
import React, { useRef, useState } from "react";
import { sendPost } from "../../lib/lib";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";
import Select from "react-select";
import "../../CSS/heLanding.css";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import Cookies from "js-cookie";
import Terms from "../../Components/Terms";

const HeLanding = () => {
  const [loader, setLoader] = useState(false);
  const [operator, setOperator] = useState(null);

  const aniRef = useRef(null);

  // console.log(Opref.current.value)
  let lang = localStorage.getItem("lan");
  const nav = useNavigate();

  const fromHandel = async (e) => {
    e.preventDefault();
    const number = await aniRef.current.value;

    if (number.length <= 5 || number.length > 12) {
      toast.info("Enter A valid Number");
      return;
    }

    if (!operator || operator === undefined) {
      toast.info("Select a Operator");
      return;
    }
    localStorage.setItem("operator", operator.elable);
    let msisdn = "";
    //country-code-prefiix
    if (
      number.charAt(0) === "9" &&
      number.charAt(1) === "6" &&
      number.charAt(2) === "6"
    ) {
      msisdn = number;
    } else {
      msisdn = "966" + number;
    }
    localStorage.setItem("r_zain_msi", msisdn);
    const subscription = {
      msisdn,
      api_buychannel: "WEB",
      api_langid: lang === "ENGLISH" ? 1 : 0,
      operator: operator.elable,
    };
    setLoader(true);
    const { error, data } = await sendPost({ url: "send-pin", subscription });
    setLoader(false);
    console.log(data);
    if (error) {
      // console.log(error);
      toast.error("Internal Server Error");
    }
    if (data) {
      if (data.description === "Invalid CODE") {
        toast.info("Enter a Valid Number");
        return;
      }
      toast(data.description);
      if (data.result === 1) {
        localStorage.setItem("uuid", data.clickid);
        nav(
          `/confirm?api_msisdn=${msisdn}&api_langid=${subscription.api_langid}&clickid=${data.clickid}`
        );
      } else if (data.result === 2) {
        localStorage.setItem("r_zain_msi", msisdn);
        // Cookies.set("zainx-msisdn", data.f_msisdn, { expires: 7 });
        return nav("/redirect");
      } else {
        // nav("/");
        return;
      }
    }
    return;
  };

  return (
    <React.Fragment>
      <div className="form-bg">
        <div className="flex pt-1 justify-between items-center mb-2 md:mb-5">
          <div className="pl-2 md:pl-5 md:text-2xl sm:text-lg text-sm">
            <h1>Mecca Game Zone</h1>
          </div>
          <div className="pr-1 ">
            <ToggleLanguage />
          </div>
        </div>
        <HomeContainer lang={lang} className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-horizontal">
                <form
                  onSubmit={fromHandel}
                  autoComplete="off"
                  autoCorrect="off"
                  className="cus-form"
                >
                  <div className="heading text-center">
                    <LanguageSwitch
                      arabic={"أدخل رقم هاتفك المحمول أدناه"}
                      english={"Enter your Mobile number below"}
                    />
                  </div>
                  <div className="form-group">
                    <span className="phone-icon">
                      <BsPhone size={25} color="#d31b88" />
                    </span>
                    <input
                      autoComplete="false"
                      autoFocus={false}
                      autoSave="false"
                      ref={aniRef}
                      required
                      name="mobile"
                      type="number"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <div className=" flex justify-center">
                      <Select
                        placeholder={
                          <LanguageSwitch
                            english={"Choose Operator"}
                            arabic={"اختر عامل التشغيل"}
                          />
                        }
                        className=" text-black cursor-pointer mb-2 text-sm md:text-lg text-center items-center"
                        value={operator}
                        options={[
                          {
                            value: lang === "ENGLISH" ? "Zain" : "زين",
                            label: lang === "ENGLISH" ? "Zain" : "زين",
                            elable: "Zain",
                          },
                        ]}
                        onChange={(v) => setOperator(v)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="he-btn">
                      {loader ? (
                        <DotLoader
                          size={17}
                          color="white"
                          className=" items-center m-auto"
                        />
                      ) : (
                        <LanguageSwitch
                          english={"Subscribe"}
                          arabic={"اشترك"}
                        />
                      )}
                    </button>
                  </div>
                  <div className="content">
                    <Terms />
                    <p>
                      <LanguageSwitch
                        arabic={
                          "*تم تحصيل مبلغ الضريبة لعملاء مسبق الدفع عند عملية شحن الرصيد*"
                        }
                        english={
                          "*The Tax amount was collected to prepaid users upon charging*"
                        }
                      />
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </HomeContainer>
      </div>
    </React.Fragment>
  );
};

export default HeLanding;

const HomeContainer = styled.div`
  /* background-image: linear-gradient(to right, #8e0e00, #1f1c18); */
  /* background-image: linear-gradient(to right, #c31432, rgb(36, 11, 54)); */
  direction: ${(props) => (props.lang === "ENGLISH" ? "ltr" : "rtl")};
  /* padding-top: 40px; */
  /* @media screen and (max-width: 280px) {
      padding-top: 25px;

      h3 {
        font-size: 12px;
      }
      input {
        width: 100%;
      }
  } */
`;
