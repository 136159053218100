import axios from "axios"

export const QueryParam =  () => {
     return new URLSearchParams(window.location.search);
};

export const sendPost = async ( payload ) => {
    const { url, subscription } = await payload;
    // console.log(payload);
    
    try {
        const resp = await axios.post(`/api/${url}`, {
            subscription, 
        });
        const data = await resp.data;
        // console.log(resp);
        return {
            error: null,
            data: data,
        };

    } catch (error) {
        const { response } = await error;
        return {
            error: response,
            data: null,
        };
    }

};

export const getRequest = async ({ action, query }) => {

    try {
        const res = await axios.get(`/api/${action}${query}`);
        const data = await res.data;
        return {
            error: null,
            data
        }
    } catch (error){
        return {
            error,
            data: null,
        }
    }

};