import { useEffect } from "react";
import { useState } from "react";

export const ToggleLanguage = () => {
    const [ showMenu, setShowMenu ] = useState(false);
    const language = localStorage.getItem('lan');

    useEffect(() => {

        if(!language || language == "null") {
            localStorage.setItem("lan", "ENGLISH"); //default
            window.location.reload();
        }
    }, [language]);

    return (
    <>
    <div 
    onClick={() => setShowMenu(!showMenu)}
    className=" md:w-52 max-sm:w-56  text-center text-white hover:text-blue-800 p-2 rounded cursor-pointer">
      {`Language: ${language}`}
    </div>

    <div
     className={`${showMenu ? "block" : "hidden"} duration-100 language-options absolute bg-gray-50
     rounded mt-1 cursor-pointer w-64 md:w-52 p-1 max-sm:w-56 z-30`}>

        <button
         onClick={() => {
          localStorage.setItem("lan", "ENGLISH");
          setShowMenu(false)
          window.location.reload()}
        }
         className="w-full mb-2 text-center cursor-pointer border hover:text-blue-700 text-black">English</button>
        <button
         onClick={() => {
          localStorage.setItem("lan", "عربي");
          setShowMenu(false)
          window.location.reload()}
        }
         className="w-full mb-2 text-center cursor-pointer  border hover:text-blue-700 text-black">عربي</button>
    </div>
    
    </>
    );
};