/* eslint-disable react/prop-types */

const LanguageSwitch = ({ english, arabic,  }) => {
    let lang = localStorage.getItem("lan");

    return (
        <>

        {
            lang === "ENGLISH" ? english : arabic
        }

        </>
    )
}

export default LanguageSwitch;