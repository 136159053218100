import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo_gameit from "../Media/Documentation/assets/img/logo_gameit.png";

const Header = (data) => {

  //To go on other page
  const navigate=useNavigate();

  //State to store search results
  const[searchList,setSearchList]=useState([]);

  //Method to show entered text
  const handleSearch=(search)=>{
    // console.log("search is ",search);
    // console.log("data is ",data);
    
    let arr=[];
    data.data.forEach(e=>{
      e.game.forEach(f=>{
        // console.log("f ",f.name);

        if(search!=='' && search!==' ' && 
          (f.name.toLowerCase().startsWith(search) || f.name.startsWith(search)))
        {
          // console.log(f.name);
          arr.push(f.name);
        }
      })
    })
    // console.log("arry is ",arr);
    setSearchList(arr);
  }

  //Method to play that search game by its name
  const handleSearchPlay=(name)=>{
    // console.log("game name is ",name);
    
    //Get Selected Game
    data.data.forEach(e=>{
      e.game.forEach(f=>{
        if(f.name===name)
        {
          // console.log(f.game);
          window.location.href=f.game;
        }
      })
    })

  }

  return (
    <header>
      <div id="sticky-header" className="main-header">
        <div className="container-fluid container-full-padding">
          <div className="row">
            <div className="col-12">
              <div className="main-menu">
                <nav>
                  <div className="logo">
                    <span style={{cursor:"pointer"}} onClick={()=>{
                      navigate("/redirect");
                    }}>
                      <img
                        src={logo_gameit}
                        //   src="../Documentation/assets/img/logo_gameit.png"
                        alt="Logo"
                      />
                      {/* <h1>Mecca Game Zone</h1> */}
                    </span>
                  </div>
                  <div
                    id="mobile-menu"
                    className="navbar-wrap d-none d-lg-flex"
                  >
                    <ul>
                      <li className="show">
                        <a href="#Sports">SPORTIFS</a>
                      </li>
                      <li>
                        <a href="#Funny">Funny</a>
                      </li>
                      <li>
                        <a href="#Action">D'ACTION</a>
                      </li>
                      <li>
                        <a href="#Adventure">D'AVENTURE</a>
                      </li>
                      <li>
                        <a href="#Racing">Racing</a>
                      </li>
                      <li>
                        <a href="#Kids">Kids</a>
                      </li>
                      <li>
                        <a href="#Arcade">ARCADE</a>
                      </li>
                      <li>
                        <a href="#Puzzle">PUZZLE</a>
                      </li>
                    </ul>
                  </div>
                  <div className="header-action">
                    <ul>
                      <li className="header-search">
                        <a
                          data-toggle="modal"
                          data-target="#search-modal"
                          href="/"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="mobile-menu"></div>
            </div>
            {/* <!-- Modal Search --> */}
            <div
              className="modal fade"
              id="search-modal"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <form>
                    <input type="text" placeholder="Search here..." onChange={(e)=>{
                      handleSearch(e.target.value);
                    }}/>
                    <button>
                      <i className="fa fa-search"></i>
                    </button>
                    <div className="search-main-div">
                    {searchList.length>0 && searchList.map((val,key)=>{
                      // console.log("v ",val);
                      return(
                          <div key={key} className="search-div"><span className="search-span"
                          style={{cursor:"pointer"}} 
                          onClick={()=>{
                            handleSearchPlay(val);
                          }}>{val}</span></div>
                      )
                    })}
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;