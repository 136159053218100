import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../Pages/Home";
import PageNotFound from "../Pages/PageNotFound";
import SeeAll from "../Pages/SeeAll";
import ErrorPage from "../Pages/Error";
import HeLanding from "../Pin-flow/HomePage/HeLanding.jsx";
import ConfirmPage from "../Pin-flow/ConfirmPage/ConfirmPage.jsx";
import Welcome from "../Pin-flow/WelcomePage/Welcome";
import Cookies from "js-cookie";

const Routing = () => {
  const nav = useNavigate();

  useEffect(() => {
    const msisdn = Cookies.get("zainx-msisdn");
    const pathname = window.location.pathname;

    if ((!msisdn || msisdn === "null" || msisdn === null) && pathname === "/redirect") {
      nav("/");
    } else if (pathname === "/welcome") {
      console.log("Welcome....");
    } else if (msisdn && msisdn != "null") {
      nav("/redirect");
    }
  }, []);

  return (<>

    <Routes>
      <Route path="/" exact={true} element={<HeLanding />}></Route>
      <Route path="/confirm" exact={true} element={<ConfirmPage />}></Route>
      <Route path="/redirect" exact={true} element={<Home />}></Route>
      <Route path="/welcome" exact={true} element={<Welcome />}></Route>
      <Route path="/seeAll" exact={true} element={<SeeAll />}></Route>
      <Route path="*" exact={true} element={<PageNotFound />}></Route>
      <Route path="/error" exact={true} element={<ErrorPage />}></Route>
    </Routes>

  </>)
}
export default Routing;