import React from 'react'
import LanguageSwitch from '../Pin-flow/Language/LanguageSwitch'

const Terms = () => {
    return (
        <>
            <p>
                <LanguageSwitch
                    arabic={
                        "خدمة Mecca Game Zone هي خدمة اشتراك حيث ستحصل على محتوى ألعاب غير محدود."
                    }
                    english={
                        "Mecca Game Zone is a subscription service for which you would receive unlimited games content."
                    }
                />
            </p>
            <p>
                <LanguageSwitch
                    arabic={
                        "لمستخدمي زين، سيتم تجديد الاشتراك بمبلغ 8.625 ريال سعودي أسبوعيًا تلقائيًا حتى تقوم بإلغاء الاشتراك. يمكنك إلغاء الاشتراك في هذه الخدمة في أي وقت عن طريق إرسال رسالة نصية تحتوي على U31 إلى الرقم 702003."
                    }
                    english={
                        `For Zain users, a subscription would be renewed at 8.625 SAR/ Weekly,
                automatically until you unsubscribe. You can 
                unsubscribe from this service, anytime, by sending  U31 to 702003.`
                    }
                />
            </p>
            <p>
                <LanguageSwitch
                    english={
                        "For prepaid lines 15% VAT on above service fee is already collected upon credit voucher purchase For postpaid lines 15% VAT is added to above service fee on the monthly bill."
                    }
                    arabic={
                        "النسبة لخطوط الدفع المسبق، يتم تحصيل 15% ضريبة القيمة المضافة على رسوم الخدمة أعلاه عند شراء قسيمة ائتمانية. بالنسبة لخطوط الدفع الآجل، يتم إضافة 15% ضريبة القيمة المضافة إلى رسوم الخدمة المذكورة أعلاه على الفاتورة الشهرية "
                    }
                />
            </p>
        </>
    )
}

export default Terms