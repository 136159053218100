import LanguageSwitch from "../../Pin-flow/Language/LanguageSwitch";
import { styled } from "styled-components";
import Cookies from "js-cookie";
import { useState } from "react";
import { QueryParam, getRequest } from "../../lib/lib";
import { toast } from "react-toastify";
import DotLoader from "react-spinners/DotLoader";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import { useNavigate } from "react-router-dom";
import "../../CSS/heLanding.css";

const Welcome = () => {
  const [loader, setLoader] = useState(false);
  const lang = localStorage.getItem("lan");
  const msisdn = localStorage.getItem("r_zain_msi");
  const op = localStorage.getItem("operator");
  const uuid = localStorage.getItem("uuid");
  // const msisdn = Cookies.get('msisdn');
  const nav = useNavigate();

  const q = QueryParam();

  const getSubStatus = async () => {
    const query = `?msisdn=${q.get("msisdn") || msisdn}&langid=${
      q.get("langid") || lang === "ENGLISH" ? "1" : "0"
    }&operator=${op || "Zain"}&uuid=${q.get("clickid") || uuid}`;
    // console.log(query)
    setLoader(true);
    const { error, data } = await getRequest({
      action: "check-valid-sub",
      query: query,
    });
    setLoader(false);
    if (error) {
      toast.error("Internal Server Error");
      return;
    }
    if (data) {
      // toast.info(data.msg);
      // console.log(data, "======== SUB API DATA ========");
      if (data.result == 1) {
        Cookies.set("zainx-msisdn", msisdn, { expires: 7 });
        nav("/redirect");
      } else {
        // nav("/welcome");
        return;
      }
      return;
    }
  };

  return (
    <div className="form-bg">
      <div className="flex pt-1 justify-between items-center mb-2 md:mb-5">
        <div className="pl-2 md:pl-5 md:text-2xl sm:text-lg text-sm">
          <h1>Mecca Game Zone</h1>
        </div>
        <div className="pr-1 ">
          <ToggleLanguage />
        </div>
      </div>
      <PageContainer lang={lang} className="container w-full mt-28">
        <div className=" flex justify-center items-center">
          <div className="w-full">
            <div className="form-horizontal">
              <div className="heading text-center">
                <LanguageSwitch
                  arabic={"شكرا لاشتراكك"}
                  english={"Thankyou for your subscription"}
                />
              </div>

              <div className="form-group">
                <button
                  disabled={loader}
                  onClick={getSubStatus}
                  className="he-btn block text-center"
                >
                  {loader ? (
                    <DotLoader
                      size={17}
                      className=" items-center m-auto"
                      color="white"
                    />
                  ) : (
                    <LanguageSwitch english={"Continue"} arabic={"يكمل"} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default Welcome;

const PageContainer = styled.div`
  direction: ${(props) => (props.lang === "ENGLISH" ? "ltr" : "rtl")};
`;
