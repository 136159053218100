import React, { useState } from "react";
import { styled } from "styled-components";

import "../../CSS/heLanding.css";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { QueryParam, getRequest } from "../../lib/lib";
import LanguageSwitch from "../Language/LanguageSwitch";
import DotLoader from "react-spinners/DotLoader";
import { ToggleLanguage } from "../Language/ToggleLanguage";
import Cookies from "js-cookie";
import Terms from "../../Components/Terms";

const ConfirmPage = () => {
  const [loader, setLoader] = useState(false);
  const [otp, setOtp] = useState("");
  const lang = localStorage.getItem("lan");
  const op = localStorage.getItem("operator");
  const r_msisdn = localStorage.getItem("r_zain_msi");
  const nav = useNavigate();

  const formSubmit = async (ev) => {
    ev.preventDefault();
    if (otp.length < 4) {
      toast.info("Enter OTP");
      return;
    }
    const q = QueryParam();
    const query = `?msisdn=${
      q.get("api_msisdn") || r_msisdn
    }&api_langid=${q.get("api_langid")}&api_pincode=${otp}&clickid=${q.get(
      "clickid"
    )}&channel=WEB&operator=${op || "Zain"}`;
    try {
      setLoader(true);
      const { error, data } = await getRequest({ action: "verify-otp", query });

      console.log(data, "data data data");
      if (error) {
        setLoader(false);
        toast.error("Internal server Error");
        return;
      }
      if (data) {
        toast(data.description);
        if (data.result === 2) {
          setLoader(false);
          Cookies.set("zainx-msisdn", data.msisdn || q.get("api_msisdn"), {
            expires: 7,
          });
          nav("/redirect");
        }
        if (data.result === 1) {
          setTimeout(() => {
            setLoader(false);
            nav(
              `/welcome?msisdn=${q.get("api_msisdn")}&langid=${q.get(
                "api_langid"
              )}&uuid=${q.get("clickid") || localStorage.getItem("uuid")}`
            );
          }, 4000);
        }
      }
    } catch (ex) {
      // console.log(ex.response);
      setLoader(false);
      toast.error("Network Error");
      return;
    }
  };
  return (
    <div className="form-bg">
      <div className="flex pt-1 justify-between items-center mb-2 md:mb-5">
        <div className="pl-2 md:pl-5 md:text-2xl sm:text-lg text-sm">
          <h1>Mecca Game Zone</h1>
        </div>
        <div className="pr-1 ">
          <ToggleLanguage />
        </div>
      </div>
      <PageContainer lang={lang} className="container w-full">
        <div className=" flex justify-center items-center">
          <div className="">
            <div className="form-horizontal">
              <form
                autoComplete="off"
                autoCorrect="off"
                onSubmit={formSubmit}
                className="cus-form"
              >
                <div className="heading text-center">
                  <LanguageSwitch
                    arabic={"دخل الرقم الرسي الذي تلقيته ع بالرسالة القصربة"}
                    english={"Enter the PIN you received by SMS "}
                  />
                </div>
                <div className="cus-otp">
                  <OTPInput
                    inputStyle={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "9px",
                      marginBottom: "10px",
                      color: "black",
                      fontSize: "20px",
                      direction: "ltr !important",
                    }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="form-group">
                  <button disabled={loader} type="submit" className="he-btn">
                    {loader ? (
                      <DotLoader
                        size={17}
                        className=" items-center m-auto"
                        color="white"
                      />
                    ) : (
                      <LanguageSwitch english={"Subscribe"} arabic={"اشترك"} />
                    )}
                  </button>
                </div>
                <div className="content">
                 <Terms />
                  <p>
                    <LanguageSwitch
                      arabic={
                        "*تم تحصيل مبلغ الضريبة لعملاء مسبق الدفع عند عملية شحن الرصيد*"
                      }
                      english={
                        "*The Tax amount was collected to prepaid users upon charging*"
                      }
                    />
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default ConfirmPage;

const PageContainer = styled.div`
  direction: ${(props) => (props.lang === "ENGLISH" ? "ltr" : "rtl")};
`;
